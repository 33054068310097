/* eslint-disable max-len, react-hooks/exhaustive-deps, import/prefer-default-export */
import axios from 'axios';
import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useMutation } from 'react-query';
import { useMutationState, useTheme } from 'src/hooks';

const initialValues = {
  name: '',
  email: '',
  linkedin: '',
  message: '',
  cv: '',
  cvFile: null,
  privacy_policy: false,
  webform_id: 'application',
};

const formMutationQuery = (data) =>
  axios({
    method: 'post',
    url: '/api/send-application',
    headers: { 'Content-Type': 'application/json' },
    data,
  });
const uploadFileMutationQuery = (data) =>
  axios({
    method: 'post',
    url: '/api/upload-cv',
    headers: { 'Content-Type': 'application/octet-stream', 'Content-Disposition': `file; filename="${data.cvFile.name}"` },
    data: data.cvFile,
  });
const validateTokenMutationQuery = (data) =>
  axios({
    method: 'post',
    url: '/api/validate-captcha',
    headers: { 'Content-Type': 'application/json' },
    data: { token: data },
  });

export const useWebformApplication = () => {
  const t = useTheme();
  const formElements = [
    { name: 'name', label: t.form.name, type: 'text', required: true },
    { name: 'email', label: t.form.email, type: 'email', required: true },
    { name: 'linkedin', label: t.form.linkedin, type: 'url', required: false },
    { name: 'message', label: t.form.message, type: 'text', required: true, as: 'textarea', rows: 5 },
  ];
  const { executeRecaptcha } = useGoogleReCaptcha();
  const getTokenMutation = useMutation(() => executeRecaptcha());
  const validateTokenMutation = useMutation(validateTokenMutationQuery);
  const uploadFileMutation = useMutation(uploadFileMutationQuery);
  const formSubmitMutation = useMutation(formMutationQuery);

  const mutationDependencyArray = [getTokenMutation, validateTokenMutation, uploadFileMutation, formSubmitMutation]; // With reCaptcha.
  // const mutationDependencyArray = [uploadFileMutation, formSubmitMutation]; // Without reCaptcha.
  const { isLoading, isSuccess, isError } = useMutationState(mutationDependencyArray);

  /** 1. Initiate Formik and retrieve recaptcha token from Google on submit. */
  const onSubmit = (data) => getTokenMutation.mutate(data); // With reCaptcha.
  // const onSubmit = (data) => uploadFileMutation.mutate(data); // Without reCaptcha.
  const formik = useFormik({ initialValues, onSubmit });
  /** 2. Validate token (reCaptcha) with Backend */
  useEffect(() => getTokenMutation.isSuccess && validateTokenMutation.mutate(getTokenMutation.data), [getTokenMutation.isSuccess]);
  /** 3. Upload File (cv) */
  useEffect(() => validateTokenMutation.isSuccess && uploadFileMutation.mutate(formik.values), [validateTokenMutation.isSuccess]);
  /** 4. Send Form */
  useEffect(
    () =>
      uploadFileMutation.isSuccess &&
      formSubmitMutation.mutate({
        ...formik.values,
        cvUrl: uploadFileMutation.data?.data.url,
        cv: uploadFileMutation.data?.data._id,
        cvFilename: uploadFileMutation.data?.data.originalFilename,
        // We need to send some information regarding which job-opening the application is for!
        // Right now it's the path what's best, it might change later.
        message: [formik.values.message, 'Submitted at', window.location.pathname].join(' - '),
      }),
    [uploadFileMutation.isSuccess]
  );
  /** 5. Reset form to initial state after successful submission. */
  useEffect(() => formSubmitMutation.isSuccess && formik.handleReset({}), [formSubmitMutation.isSuccess]);

  /** Handles redirect on successful submission */
  useEffect(() => isSuccess && navigate(t.pages.thankYou.pathName, { state: { fromWebform: true } }), [isSuccess]);

  return { formElements, formik, isSuccess, isError, isLoading };
};
