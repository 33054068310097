/* eslint-disable react/no-array-index-key */
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ElementPortableText from 'src/components/ElementPortableText/ElementPortableText';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';
import WebFormApplication from 'src/components/WebFormApplication/WebFormApplication';
import WebFormContext from 'src/components/WebFormContext/WebFormContext';
import WebFormWrapper from 'src/components/WebFormWrapper/WebFormWrapper';
import { useTheme } from 'src/hooks';

function JobOpeningPage({ location, data }) {
  const { form } = useTheme();

  const jobOpening = {
    title: data.jobOpening.title ?? '[title]',
    description: data.jobOpening.description ?? '[description]',
    body: data.jobOpening._rawBody ?? [],
  };

  const seo = {
    title: jobOpening.pageSeo?.title ?? jobOpening.title,
    description: jobOpening.pageSeo?.description ?? jobOpening.description,
    image: jobOpening.pageSeo?.image?.asset?.url,
    location,
  };

  return (
    <PageLayout>
      <PageSEO {...seo} />
      <WebFormContext>
        <Container fluid className="bg-shapes-node-page px-0 pt-7">
          <Container className="py-6">
            <Row className="pt-6">
              <Col xs={12} lg={{ span: 5, offset: 2 }}>
                <h1 className="h2">{jobOpening.title}</h1>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col className="post-editor text-muted ck-editor-text py-6" xs={12}>
                    <ElementPortableText content={jobOpening.body} variant={['post', 'narrow']} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          <WebFormWrapper
            title={form.interested}
            subtitle={form.interestedSubtitle}
            data={[{ component: <WebFormApplication />, tab: '', id: 'eu5shub5gz6qrqt4' }]}
          />
        </Container>
      </WebFormContext>
    </PageLayout>
  );
}

export const query = graphql`
  query ($id: String!) {
    jobOpening: sanityJobOpening(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      pageSeo {
        ...getPageSeo
      }
      _rawBody
      description
    }
  }
`;

JobOpeningPage.propTypes = {
  data: PropTypes.shape({
    jobOpening: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default JobOpeningPage;
